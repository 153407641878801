import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import SectionWrapper from '../components/SectionWrapper';
import Partners from '../components/Partners';
import PDF from '../../static/disclaimer.pdf';

const StyledContainer = styled(Container)`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin-top: 9rem;
  }
`;

const LinksWrapper = styled.div`
  display: flex;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    justify-content: space-between;
    width: 100%;
    margin-top: 5rem;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    align-self: flex-end;
  }

  a {
    font-family: ${({ theme }) => theme.fontFamilyHeading};
    text-transform: uppercase;
    border-bottom: 2px solid ${({ theme }) => theme.black};

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-left: 4.5rem;
    }
  }
`;

const Footer = () => {
  return (
    <StyledContainer>
      <SectionWrapper>
        <Partners />
        <LinksWrapper>
          <a href={PDF}>Disclaimer</a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bravoure.nl/en/?utm_source=Website%20-%20Footer&utm_medium=Website&utm_campaign=Bravoure%20-%202020&utm_content=Corona%20-Crisis%20-Portal"
          >
            Website by Bravoure
          </a>
        </LinksWrapper>
      </SectionWrapper>
    </StyledContainer>
  );
};

export default Footer;
