import styled from 'styled-components';

const Button = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  padding: 11px 18px 11px 14px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }) => theme.black};
  border: 3px solid ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 11px 18px 11px 18px;
    font-size: 23px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
  }

  svg {
    margin: ${({ alt }) => (alt ? '0 0 0 1rem' : '0 1rem 0 0')};
  }

  ${({ theme, white }) =>
    white &&
    `
    background-color: ${theme.white};
    border: 3px solid ${theme.white};
    color: ${theme.black};
    &:hover {
      background-color: ${theme.black};
      color: ${theme.white};
    }
    svg {
      path {
        stroke: ${theme.black};
      }
    }
    &:hover {
      svg {
        path {
          stroke: ${theme.white};
        }
      }
    }

    `};
`;

export default Button;
