import React, { useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Plus from '../../static/images/plus.svg';
import Button from './Button';
import Container from './Container';
import Text from './Text';
import ContentWrapper from './ContentWrapper';
import Logos from './Logos';

const Content = styled.div`
  position: relative;
`;

const Wrapper = styled.header`
  padding: 4rem 0 25rem;
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 7rem 0;
  }
`;

const Title = styled.h1`
  font-size: 7.2rem;
  max-width: 84rem;
  margin: 0 0 1.5rem;
  line-height: 0.9;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 16.4rem;
    margin: 0 0 5.5rem;
  }

  span {
    display: block;

    &:nth-child(2) {
      text-align: right;
    }
  }
`;

const StyledText = styled(Text)`
  font-size: 1.6rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 2rem;
  }
`;

const Header = () => {
  const [readMore, setReadMore] = useState(false);

  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>
            <span>Corona</span>
            <span>Crisis</span>
            <span>Portal</span>
          </Title>
          <ContentWrapper white>
            <StyledText>
              <p>
                Ben jij werkzaam in de Amsterdamse nacht (als clubeigenaar,
                promotor, artiest, boekingskantoor, creative agency,
                toeleverancier etcetera) en zit jij met vragen over de
                coronacrisis en de gevolgen daarvan? Stichting N8BM en The
                Nightlife Clerks hebben de handen ineen geslagen om jou te
                helpen.
              </p>
              {readMore && (
                <p>
                  Vanwege de maatregelen die het kabinet heeft genomen om het
                  coronavirus in te dammen, staat het nachtleven in Nederlands
                  sinds 12 maart jl. stil. Dit heeft grote (financiële) gevolgen
                  voor iedereen die werkzaam is in de nacht. Het kabinet, de
                  gemeenten en andere instanties hebben steunmaatregelen
                  gepresenteerd om de financiële klappen zoveel mogelijk op te
                  vangen. De informatie hierover is echter versnipperd en lang
                  niet altijd even duidelijk. Ook lijken sommigen wat betreft
                  steun tussen wal en schip te vallen.
                </p>
              )}
            </StyledText>
            {!readMore && (
              <Button onClick={() => setReadMore(true)} white alt={1}>
                Lees meer
                <SVG src={Plus} />
              </Button>
            )}
          </ContentWrapper>
          <Logos />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;
