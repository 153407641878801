import styled from 'styled-components';
import Text from './Text';

const Title = styled.h2`
  color: inherit;
  font-size: 2.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 3.8rem;
  }

  + ${Text} {
    margin-top: 1.5rem;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin-top: 3rem;
    }
  }
`;

export default Title;
