import React from 'react';
import styled from 'styled-components';

import Text from '../components/Text';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

const StyledText = styled(Text)`
  max-width: 46rem;
`;

const FAQ = () => {
  return (
    <ContentWrapper>
      <Title>Partners</Title>
      <StyledText as="h4">
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bravoure.nl"
          >
            Bravoure
          </a>{' '}
          /{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.khn.nl/"
          >
            {' '}
            Koninklijke Horeca Nederland{' '}
          </a>
          /
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://evenementenvereniging.amsterdam/"
          >
            {' '}
            Evenementen Vereniging Amsterdam
          </a>{' '}
          / Overleg Amsterdamse Clubs /{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.schipperlegal.nl/"
          >
            Schipper Legal
          </a>{' '}
          /{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://norisk.eu/"
          >
            Klap No Risk
          </a>{' '}
          /{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greymen.nl/
"
          >
            {' '}
            Greymen
          </a>{' '}
          /{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.wesselvanderlans.nl/
"
          >
            {' '}
            Wessel Van der Lans
          </a>
        </p>
      </StyledText>
    </ContentWrapper>
  );
};

export default FAQ;
