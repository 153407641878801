import styled from 'styled-components';

const SectionWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 5rem 0;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 7.6rem 0;
  }
`;

export default SectionWrapper;
