import styled from 'styled-components';
import Button from './Button';

const Text = styled.div`
  color: inherit;
  font-size: 1.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 1.8rem;
  }

  + ${Button} {
    margin-top: 4rem;
  }
`;

export default Text;
