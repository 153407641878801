import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import Header from './Header';
import Footer from './Footer';
import SocialImage from '../../static/images/social-image.jpg';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet title="Corona Crisis Portal - Stichting Nachtburgemeester Amsterdam">
          <meta
            name="description"
            content="Ben jij werkzaam in de Amsterdamse nacht en zit jij met vragen over de coronacrisis en de gevolgen daarvan? Stichting N8BM en The Nightlife Clerks hebben de handen ineen geslagen om jou te helpen."
          />
          <meta
            property="og:title"
            content="Corona Crisis Portal - Stichting Nachtburgemeester Amsterdam"
          />
          <meta
            property="og:description"
            content="Ben jij werkzaam in de Amsterdamse nacht en zit jij met vragen over de coronacrisis en de gevolgen daarvan? Stichting N8BM en The Nightlife Clerks hebben de handen ineen geslagen om jou te helpen."
          />
          <meta name="image" content={SocialImage} />
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <GlobalStyle />
        <Header />
        <main>{children}</main>
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;
