import styled from 'styled-components';

const ContentWrapper = styled.div`
  flex: 1;
  margin: 0;
  max-width: 63rem;
  color: ${({ theme, white }) => (white ? theme.white : theme.black)};
`;

export default ContentWrapper;
