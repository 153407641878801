import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Logo1 from '../../static/images/logo1.svg';
import Logo2 from '../../static/images/logo2.svg';

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 4.8rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
    right: 0;
  }

  svg {
    margin-bottom: 2.4rem;
  }
`;

const Logos = () => {
  return (
    <Wrapper>
      <SVG src={Logo1} />
      <SVG src={Logo2} />
    </Wrapper>
  );
};

export default Logos;
