import { createGlobalStyle } from 'styled-components';
import InterstateLightWoff from '../../static/fonts/Interstate-Light.woff';
import InterstateLightWoff2 from '../../static/fonts/Interstate-Light.woff2';
import BureauGrotWoff from '../../static/fonts/BureauGrot-Medium.woff';
import BureauGrotWoff2 from '../../static/fonts/BureauGrot-Medium.woff2';

// set basic styling and 'normalize' things
const GlobalStyle = createGlobalStyle`
  
  @font-face {
    font-family: 'Interstellar Light';
    src: url(${InterstateLightWoff}) format('woff'),
    url(${InterstateLightWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BureauGrot Medium';
    src: url(${BureauGrotWoff}) format('woff'),
      url(${BureauGrotWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: auto;
    font-size: 1.6rem;
    line-height: 1.25;
    font-family: ${({ theme }) => theme.fontFamily};
    background-color: #FFF;
    color: ${({ theme }) => theme.textColor};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
    font-weight: normal;
    line-height: 1.1;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    padding: 0;
    
    + p {
     margin-top: 2rem; 
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: ${({ theme }) => theme.font};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }
`;

export default GlobalStyle;
