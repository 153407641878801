const theme = {
  // Colours
  primaryColor: '#000',

  white: '#fff',
  black: '#000',
  red: 'red',

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    '"Interstellar Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    '"BureauGrot Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1400px',

  // Breakpoints
  //media queries
  respondTo: {
    desktopExtraBig: `min-width: 1600px`,
    desktopSuperBig: `min-width: 1400px`,
    desktopBig: `min-width: 1280px`,
    desktop: `min-width: 1024px`,
    tablet: `min-width: 768px`,
    mobile: `max-width: 480px`,
  },

  //mixin
  transition: (property, duration) =>
    `transition: ${property} ${duration}s cubic-bezier(0.86, 0, 0.07, 1)`,
};

export default theme;
